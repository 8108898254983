<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="customReportAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchcustomReportWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col sm="6" md="6" lg="8" xl="8" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getcustomReportDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="customReportObjList && customReportObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="customReportObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(cust_report_name)="data">
                    {{data.item.cust_report_name}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="customReportEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger mr-1 mb-1" @click="showCustomReportDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                    <b-button variant=" iq-bg-primary mb-1" @click="customReportDownload(data.item)" size="sm"><i class="fa fa-download"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelCustomReportAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
    >
      <CustomReportAdd :propOpenedInModal="true" @emitCloseCustomReportAddModal="closeCustomReportAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="customReportAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCustomReportAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelCustomReportEdit"
      scrollable
      :title="cvEditModalHeader"
      size="lg"
    >
      <CustomReportEdit :propOpenedInModal="true" :propCustomReportObj="customReportEditObj" @emitCloseCustomReportEditModal="closeCustomReportEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="customReportEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCustomReportEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelCustomReportDelete" size="lg" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelCustomReportDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="customReportDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { CustomReports } from "../../../FackApi/api/CustomReport"
import CustomReportAdd from "./CustomReportAdd"
import CustomReportEdit from "./CustomReportEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "CustomReportList",
  components: {
    CustomReportAdd,
    CustomReportEdit
  },
  data () {
    return {
      apiName: "custom_report_list",
      cvCardTitle: "Custom Downloadable Reports",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Custom Report",
      cvAddModalHeader: "Add Custom Report",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Custom Report",
      showModelCustomReportAdd: false,
      showModelCustomReportEdit: false,
      showModelCustomReportDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Report Name", key: "cust_report_name", class: "text-left align-text-top", sortable: true },
        { label: "Description", key: "cust_report_des", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      customReportObjList: [],
      customReportEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    await this.getDateFilter()
    socialvue.index()
    this.customReportList()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * searchcustomReportWithParams
     */
    async searchcustomReportWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.customReportList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.customReportList(true)
    },
    /**
     * Calling Function as per whereFilter
    */
    async getcustomReportDateWise () {
      this.customReportList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * customReportDownload
    */
    async customReportDownload (custQuery) {
      try {
        let custReportObj = {
          cust_report_id: custQuery.cust_report_id
        }

        this.whereFilter.cust_report_id = custQuery.cust_report_id
        let customReportDownloadListResp = await CustomReports.customReportDownload(this, custReportObj, this.whereFilter)

        if (customReportDownloadListResp.resp_status) {
          window.open(customReportDownloadListResp.resp_data, "_black")
        }
        else {
          this.toastMsg = customReportDownloadListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customReportList() and Exception:", err)
      }
    },
    /**
     * customReportList
     */
    async customReportList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let customReportListResp = await CustomReports.customReportList(this, this.whereFilter)
        if (customReportListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.customReportObjList = [ ...customReportListResp.resp_data.data ]
          }
          else {
            this.customReportObjList = [ ...this.customReportObjList, ...customReportListResp.resp_data.data ]
          }

          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.customReportObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.customReportObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = customReportListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customReportList() and Exception:", err)
      }
    },
    /**
     * customReportAdd
     */
    customReportAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("custom_report_add")
        }
        else {
          this.showModelCustomReportAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customReportAdd() and Exception:", err.message)
      }
    },
    /**
     * customReportEdit
     */
    customReportEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("custom_report_edit/" + this.customReportEditObj.cust_report_id)
        }
        else {
          this.customReportEditObj = item
          this.showModelCustomReportEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customReportEdit() and Exception:", err.message)
      }
    },
    /**
     * showCustomReportDeleteDialog
     */
    showCustomReportDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelCustomReportDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showCustomReportDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * customReportDelete
     */
    async customReportDelete () {
      try {
        let customReportDelResp = await CustomReports.customReportDelete(this, this.delObj.cust_report_id)
        await ApiResponse.responseMessageDisplay(this, customReportDelResp)

        if (customReportDelResp && !customReportDelResp) {
          this.showModelCustomReportDelete = false
          return false
        }

        let index = this.customReportObjList.indexOf(this.delObj)
        this.customReportObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelCustomReportDelete = false
      }
      catch (err) {
        console.error("Exception occurred at customReportDelete() and Exception:", err.message)
      }
    },
    /**
     * closeCustomReportAddModal
     */
    closeCustomReportAddModal (customReportAddData) {
      try {
        if (customReportAddData) {
          if (this.customReportObjList && this.customReportObjList.length >= 1) {
            let customReportObjLength = this.customReportObjList.length
            let lastId = this.customReportObjList[customReportObjLength - 1]["id"]
            customReportAddData.id = lastId + 1
          }
          else {
            this.customReportObjList = []
            customReportAddData.id = 11111
          }

          customReportAddData.created_on = moment()
          this.customReportObjList.unshift(customReportAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelCustomReportAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closecustomReportAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeCustomReportEditModal
     */
    closeCustomReportEditModal () {
      try {
        this.showModelCustomReportEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeCustomReportEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
